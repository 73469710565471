import React from 'react';
import { useTranslation } from 'react-i18next';
import FormRow from '../../../../components/forms/FormRow';
import PhotosInput from '../../../../components/forms/PhotosInput';
import { useCampaignForm } from '../../hooks/CampaignContext';

const PhotosInputRow = () => {
  const { t } = useTranslation('campaigns');
  const { formData, addPhoto, deletePhoto } = useCampaignForm();

  return (
    <FormRow label={t('add-campaign.photos')}>
      <PhotosInput photos={formData.photos} addPhoto={addPhoto} deletePhoto={deletePhoto} />
    </FormRow>
  );
};

export default PhotosInputRow;
