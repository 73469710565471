import { useEffect, useState } from 'react';
import { useGlobalError } from '../../../providers/GlobalErrorProvider';
import { ChannelStatus, ChannelType, UserChannel } from '../../../services/Notification/notificationService.dto';
import { notificationService } from '../../../services/Notification/notificationService';

export const useUserChannels = (userId: number) => {
  const [userChannels, setUserChannels] = useState<UserChannel[]>();
  const [isLoading, setIsLoading] = useState(false);
  const { handleError } = useGlobalError();

  useEffect(() => {
    fetchUserChannels();
  }, [userId]);

  const updateChannelState = (type: ChannelType, state: ChannelStatus) => {
    setUserChannels(prevState =>
      prevState?.map(channel => {
        if (channel.type === type) return { ...channel, state: state };
        else return channel;
      })
    );
  };

  const fetchUserChannels = () => {
    setIsLoading(true);
    notificationService
      .fetchUserChannels(userId)
      .then(response => setUserChannels(response.data))
      .catch(handleError)
      .finally(() => setIsLoading(false));
  };

  const updateUserChannel = (userId: number, type: ChannelType, state: ChannelStatus) => {
    setIsLoading(true);
    notificationService
      .updateUserChannel(userId, type, state)
      .then(() => updateChannelState(type, state))
      .catch(handleError)
      .finally(() => setIsLoading(false));
  };

  return { userChannels, updateUserChannel, isLoading };
};
