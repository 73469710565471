import React from 'react';
import { useTranslation } from 'react-i18next';
import DropdownWithLabel from '../../../../components/inputs/DropdownWithLabel';
import { useItemForm } from '../../hooks/ItemContext';
import { useGlobalData } from '../../../../providers/GlobalDataProvider';
import FormRow from '../../../../components/forms/FormRow';

const CategoryInputRow = () => {
  const { t } = useTranslation('items');
  const { itemCategories } = useGlobalData();
  const { formData, setCategory } = useItemForm();
  const validCategories = itemCategories.filter(category => category.itemType === formData.type);
  const selectedCategory =
    formData.category && formData.category.itemType === formData.type
      ? formData.category.displayName
      : validCategories[0].displayName;
  return (
    <FormRow label={t('add-item.category')}>
      <DropdownWithLabel
        value={selectedCategory}
        options={[...validCategories.map(category => category.displayName)]}
        label={''}
        hideEmptyOption={true}
        onChange={value => setCategory(itemCategories.find(category => category.displayName === value))}
        minWidth={500}
      />
    </FormRow>
  );
};

export default CategoryInputRow;
