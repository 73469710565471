import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import { FormHeader } from '../../../../components/typography/PopoverTypography';
import { ConfirmButton } from '../../../../components/buttons/popoverButtons';
import { RecipientDto } from '../data/adminChannelTypes';
import EmployeeSelector from '../../../users/components/userSelector/EmployeeSelector';

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 350px;
  height: auto;
`;

const RecipientContainer = styled.div`
  display: flex;
  width: 100%;
  height: 50px;
  justify-content: center;
  align-items: center;
`;

interface Props {
  notificationType: string;
  currentRecipient?: RecipientDto;
  onConfirmPress: (notificationType: string, recipient?: RecipientDto) => void;
}

const UpdateRecipientForm = ({ notificationType, currentRecipient, onConfirmPress }: Props) => {
  const { t } = useTranslation('notifications');

  const [recipient, setSetRecipient] = useState(currentRecipient);
  const isUpdated = currentRecipient?.id !== recipient?.id;

  const handleConfirmPress = () => onConfirmPress(notificationType, recipient);

  return (
    <FormContainer>
      <FormHeader>{t('admin-channels.recipient-form.header')}</FormHeader>
      <RecipientContainer>
        <EmployeeSelector user={recipient} onUserSelected={setSetRecipient} />
      </RecipientContainer>
      <ConfirmButton
        disabled={!isUpdated}
        label={
          !!recipient || !isUpdated
            ? t('admin-channels.recipient-form.update-button')
            : t('admin-channels.recipient-form.remove-button')
        }
        onClick={handleConfirmPress}
      />
    </FormContainer>
  );
};

export default UpdateRecipientForm;
