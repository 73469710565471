import { useEffect, useMemo, useState } from 'react';
import { CampaignAdminDto } from '../../../services/Campaign/campaignService.dto';
import { campaignService } from '../../../services/Campaign/campaignService';
import { useGlobalError } from '../../../providers/GlobalErrorProvider';

export const useCampaignDetails = (campaignId?: number, campaignData?: CampaignAdminDto) => {
  const { handleError } = useGlobalError();
  const [isLoading, setIsLoading] = useState(false);
  const [campaign, setCampaign] = useState<CampaignAdminDto | undefined>(campaignData);

  const info = useMemo(() => (!!campaign ? `${campaign.name} (#${campaign.id})` : ''), [campaign]);

  useEffect(() => {
    if (!!campaign || !campaignId) return;
    setIsLoading(true);
    campaignService
      .fetchSingleCampaign(campaignId)
      .then(response => setCampaign(response))
      .catch(error => handleError(error, error.message))
      .finally(() => setIsLoading(false));
  }, [campaignId, campaignData]);

  return { campaign, isLoading, info };
};
