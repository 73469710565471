import React, { createContext, FC, ReactNode, useContext, useState } from 'react';
import styled from 'styled-components';
import { Popover, PopoverOrigin } from '@mui/material';
import { v4 as uuid } from 'uuid';
import closeIcon from '../assets/svg/close.svg';
import ConfirmDialog from '../components/dialogs/ConfirmDialog';

const PopoverContainer = styled.div`
  padding: 10px 40px;
  display: flex;
  justify-content: center;
  text-align: center;
  max-width: 700px;
  max-height: 90vh;
`;

const CloseButton = styled.img`
  position: absolute;
  top: 0;
  right: 0;
  width: 44px;
  height: 44px;
  cursor: pointer;
  padding: 10px;
  z-index: 1;
`;

interface PopoverData {
  id: string;
  anchor?: SVGSVGElement | HTMLDivElement;
  content: ReactNode;
}

interface PopupContextType {
  showPopover: (content: ReactNode, anchor?: SVGSVGElement | HTMLDivElement) => void;
  hidePopover: () => void;
  showConfirmDialog: (msg: string, onYesClick: () => void) => void;
  showDialog: (content: ReactNode) => void;
  hideDialog: () => void;
}

const PopupContext = createContext<PopupContextType>(null!);

const PopupProvider: FC<{ children: ReactNode }> = props => {
  const [popovers, setPopovers] = useState<PopoverData[]>([]);
  const [dialogContent, setDialogContent] = useState<ReactNode>();
  const [confirmDialogContent, setConfirmDialogContent] = useState<ReactNode>();

  const showPopover = (content: ReactNode, anchor?: SVGSVGElement | HTMLDivElement) => {
    const popoverData: PopoverData = { id: uuid(), anchor, content };
    setPopovers(prevState => [...prevState, popoverData]);
  };

  const showConfirmDialog = (msg: string, onYesClick: () => void) => {
    setConfirmDialogContent(
      <ConfirmDialog
        msg={msg}
        onYesClick={() => {
          onYesClick();
          setConfirmDialogContent(undefined);
        }}
        onNoClick={() => setConfirmDialogContent(undefined)}
      />
    );
  };

  const showDialog = (content: ReactNode) => {
    setDialogContent(content);
  };

  const hidePopover = (id?: string) => {
    if (!id) setPopovers([]);
    else setPopovers(prevState => prevState.filter(popover => popover.id !== id));
  };

  const hideDialog = () => {
    setDialogContent(undefined);
  };

  const popoverOrigin: PopoverOrigin = { vertical: 'center', horizontal: 'left' };

  return (
    <PopupContext.Provider value={{ showPopover, hidePopover, showConfirmDialog, showDialog, hideDialog }}>
      {props.children}
      {popovers.map(popoverData => (
        <Popover
          key={popoverData.id}
          anchorEl={popoverData.anchor}
          anchorReference={popoverData.anchor ? undefined : 'none'}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backdropFilter: 'blur(2px)',
          }}
          PaperProps={{ style: { overflow: 'visible' } }}
          open={true}
          onClose={() => hidePopover(popoverData.id)}
          elevation={5}
          anchorOrigin={popoverData.anchor && popoverOrigin}
          transformOrigin={popoverData.anchor && popoverOrigin}>
          <PopoverContainer>
            <CloseButton
              src={closeIcon}
              alt={'close'}
              onClick={event => {
                event.preventDefault();
                hidePopover(popoverData.id);
              }}
            />
            {popoverData.content}
          </PopoverContainer>
        </Popover>
      ))}
      {!!dialogContent && dialogContent}
      {!!confirmDialogContent && confirmDialogContent}
    </PopupContext.Provider>
  );
};

const usePopup = () => useContext(PopupContext);

export { PopupProvider, usePopup };
