import { useTranslation } from 'react-i18next';
import { useGlobalError } from '../../../../providers/GlobalErrorProvider';

export const useAdminChannelErrors = () => {
  const { t } = useTranslation('notifications');
  const { handleError, errorMessage } = useGlobalError();

  const handleFetchError = (error: any) => handleError(error);

  const handleUpdateRecipientError = (error: any) => {
    const errorCode = error?.response?.data?.code;
    if (errorCode === 'NOTIFICATION_TYPE_NOT_FOUND') {
      errorMessage(t('admin-channels.errors.notification-type-not-found'));
    } else if (errorCode === 'USER_NOT_EMPLOYEE') {
      errorMessage(t('admin-channels.errors.user-not-employee'));
    } else {
      handleError(error);
    }
  };

  const handleUpdateSlackError = (error: any) => {
    const errorCode = error?.response?.data?.code;
    if (errorCode === 'NOTIFICATION_TYPE_NOT_FOUND') {
      errorMessage(t('admin-channels.errors.notification-type-not-found'));
    } else if (errorCode === 'SLACK_WEBHOOK_INCORRECT_FORMAT') {
      errorMessage(t('admin-channels.errors.webhook-incorrect-format'));
    } else if (errorCode === 'SLACK_WEBHOOK_NOT_WORKING') {
      errorMessage(t('admin-channels.errors.webhook-not-working'));
    } else {
      handleError(error);
    }
  };

  return { handleFetchError, handleUpdateRecipientError, handleUpdateSlackError };
};
