import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import SyncIcon from '@mui/icons-material/Sync';
import {
  DEFAULT_COUNTRY_CODE,
  DEFAULT_OBJECT_LOCATION,
  LocationInfoDto,
  LocationSource,
} from '../../services/Shipment/shipmentService.dto';
import { UserLocationDto } from '../../services/User/userService.dto';
import InputWithLabel from '../inputs/InputWithLabel';
import { shipmentService } from '../../services/Shipment/shipmentService';
import { useGlobalError } from '../../providers/GlobalErrorProvider';
import { theme } from '../../assets/styles/theme';

const Container = styled.div`
  padding: 5px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const StyledInput = styled(InputWithLabel)`
  flex: 1;
  max-width: unset;
  width: 100%;
  padding: 0;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
`;

const SyncButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const VALID_ZIP_LEN = 5;

interface Props {
  location: LocationInfoDto;
  userLocation?: UserLocationDto;
  setLocation: (location: LocationInfoDto) => void;
}

const LocationInput = ({ location, userLocation, setLocation }: Props) => {
  const { t } = useTranslation('common');
  const { handleError } = useGlobalError();

  const [zip, setZip] = useState(location.zipCode);
  const [city, setCity] = useState(location.city);
  const [state, setState] = useState(location.state);
  const [lat, setLat] = useState<number | undefined>(location.latitude);
  const [lon, setLon] = useState<number | undefined>(location.longitude);
  const [locationSource, setLocationSource] = useState<LocationSource | undefined>(location.locationSource);

  useEffect(() => {
    const newLocation: LocationInfoDto = {
      latitude: lat,
      longitude: lon,
      zipCode: zip,
      state,
      city,
      countryCode: DEFAULT_COUNTRY_CODE,
      locationSource,
    };
    setLocation(newLocation);
  }, [zip, city, state, lat, lon]);

  useEffect(() => {
    if (location) {
      setZip(location?.zipCode);
      setCity(location?.city);
      setState(location?.state);
      setLat(location?.latitude);
      setLon(location?.longitude);
      setLocationSource(location.locationSource);
    } else if (userLocation) {
      setZip(userLocation?.zip);
      setCity(userLocation?.city);
      setState(userLocation?.state);
      setLat(userLocation?.lat);
      setLon(userLocation?.lon);
      setLocationSource('PROFILE');
    } else {
      setLocation(DEFAULT_OBJECT_LOCATION);
      setZip(DEFAULT_OBJECT_LOCATION.zipCode);
      setCity(DEFAULT_OBJECT_LOCATION.city);
      setState(DEFAULT_OBJECT_LOCATION.state);
      setLat(DEFAULT_OBJECT_LOCATION.latitude);
      setLon(DEFAULT_OBJECT_LOCATION.longitude);
      setLocationSource(DEFAULT_OBJECT_LOCATION.locationSource);
    }
  }, [userLocation, location]);

  const syncCoords = () => {
    if (!!zip && zip.length === VALID_ZIP_LEN) {
      shipmentService
        .fetchGeocoding(zip)
        .then(response => {
          setLat(response.data.coordinates.latitude);
          setLon(response.data.coordinates.longitude);
          setLocationSource('ZIP_CODE');
        })
        .catch(e => {
          setLat(undefined);
          setLon(undefined);
          setLocationSource(undefined);
          handleError(e);
        });
    } else {
      setLat(undefined);
      setLon(undefined);
    }
  };

  return (
    <Container>
      <Row>
        <StyledInput label={t('location.zip')} onChange={setZip} value={zip || ''} />
        <StyledInput label={t('location.city')} onChange={setCity} value={city || ''} />
        <StyledInput label={t('location.state')} onChange={setState} value={state || ''} />
      </Row>
      <Row>
        <StyledInput
          label={t('location.lat')}
          onChange={lat => setLat(Number.parseFloat(lat))}
          disabled={true}
          value={lat ? lat.toString() : ''}
        />
        <StyledInput
          label={t('location.lon')}
          onChange={lon => setLon(Number.parseFloat(lon))}
          disabled={true}
          value={lon ? lon.toString() : ''}
        />
        <SyncButton title={t('common.sync-coords-btn')} onClick={syncCoords}>
          <SyncIcon style={{ color: theme.color.red, fontSize: 24 }} />
        </SyncButton>
      </Row>
    </Container>
  );
};

export default LocationInput;
